import React from "react";
import { graphql } from "gatsby";
import { Container } from "../components/container";
import GraphQLErrorList from "../components/others/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

import ProjectProject from "../components/project/blog-post";

export const query = graphql`
  query ProjectProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      publishedAt
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
      route: sanityRoute {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
        }
      }
  }
`;

const ProjectProjectTemplate = (props) => {
    const { data, errors } = props;
    const project = data && data.project;
  
    const page = data.page || data.route.page;
    const menuItems = page.navMenu && (page.navMenu.items || []);
  
    return (
      <Layout navMenuItems={menuItems}>
        {errors && <SEO title="GraphQL Error" />}
        {project && (
          <SEO
            title={project.title || "Untitled"}
            description={toPlainText(project._rawExcerpt)}
            image={project.mainImage}
          />
        )}
  
        {errors && (
         <Container>
            <GraphQLErrorList errors={errors} />
         </Container>
        )}
  
        {project && <ProjectProject {...project} />}
      </Layout>
    );
  };
  
  export default ProjectProjectTemplate;